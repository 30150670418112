<template>
  <div>
    <!-- 使用 van-cell-group 包裹输入字段，提供视觉分组 -->
    <van-cell-group inset>
      <van-field v-model="email" label="电子邮箱" placeholder="请输入您的电子邮箱" />
      <van-button type="primary" @click="sendVerification">发送验证码</van-button>
    </van-cell-group>
    <van-cell-group inset>
      <van-field v-model="code" label="验证码" placeholder="请输入您的验证码" />
      <van-button type="primary" @click="verifyCode">验证代码</van-button>
    </van-cell-group>
    <!-- 消息显示区 -->
    <div v-if="message">{{ message }}</div>
  </div>
</template>

<script>
import { sendEmail, verifyEmailCode } from '../request/api/home';

export default {
  data() {
    return {
      email: '',
      code: '',
      message: ''
    };
  },
  methods: {
    sendVerification() {
      sendEmail(this.email)
          .then(response => {
            this.message = response.data;
          })
          .catch(error => {
            this.message = '发送邮件失败';
          });
    },

    verifyCode() {
      verifyEmailCode(this.email, this.code)
          .then(response => {
            // 确保成功响应中含有预期的数据
            if (response && response.data) {
              this.message = "验证成功：" + response.data;
            } else {
              // 如果响应成功但无数据，提供默认成功消息
              this.message = "验证成功！";
            }
          })
          .catch(error => {
            // 确保错误处理时响应中含有错误信息
            if (error.response && error.response.data) {
              this.message = '验证失败：' + error.response.data;
            } else {
              // 如果错误响应中无具体错误信息，提供默认错误消息
              this.message = '验证失败，出现未预期的错误。';
            }
          });
    }
  }
}
</script>

<style scoped>
</style>
